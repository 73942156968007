import React, { useEffect, useState } from 'react'

//import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
// import 'primereact/resources/themes/bootstrap4-light-purple/theme.css'
// import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css'
// import 'primereact/resources/themes/bootstrap4-dark-purple/theme.css'
// import 'primereact/resources/themes/md-light-indigo/theme.css'
// import 'primereact/resources/themes/md-light-deeppurple/theme.css'
// import 'primereact/resources/themes/md-dark-indigo/theme.css'
// import 'primereact/resources/themes/md-dark-deeppurple/theme.css'
// import 'primereact/resources/themes/mdc-light-indigo/theme.css'
// import 'primereact/resources/themes/mdc-light-deeppurple/theme.css'
// import 'primereact/resources/themes/mdc-dark-indigo/theme.css'
// import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css'
// import 'primereact/resources/themes/tailwind-light/theme.css'
// import 'primereact/resources/themes/fluent-light/theme.css'
// import 'primereact/resources/themes/lara-light-blue/theme.css'
// import 'primereact/resources/themes/lara-light-indigo/theme.css'
// import 'primereact/resources/themes/lara-light-purple/theme.css'
// import 'primereact/resources/themes/lara-light-teal/theme.css'
// import 'primereact/resources/themes/lara-dark-blue/theme.css'
// import 'primereact/resources/themes/lara-dark-indigo/theme.css'
// import 'primereact/resources/themes/lara-dark-purple/theme.css'
// import 'primereact/resources/themes/lara-dark-teal/theme.css'
// import 'primereact/resources/themes/soho-light/theme.css'
// import 'primereact/resources/themes/soho-dark/theme.css'
import 'primereact/resources/themes/viva-light/theme.css'
// import 'primereact/resources/themes/viva-dark/theme.css'
// import 'primereact/resources/themes/mira/theme.css'
// import 'primereact/resources/themes/nano/theme.css'
// import 'primereact/resources/themes/saga-blue/theme.css'
// import 'primereact/resources/themes/saga-green/theme.css'
// import 'primereact/resources/themes/saga-orange/theme.css'
// import 'primereact/resources/themes/saga-purple/theme.css'
// import 'primereact/resources/themes/vela-blue/theme.css'
// import 'primereact/resources/themes/vela-green/theme.css'
// import 'primereact/resources/themes/vela-orange/theme.css'
// import 'primereact/resources/themes/vela-purple/theme.css'
// import 'primereact/resources/themes/arya-blue/theme.css'
// import 'primereact/resources/themes/arya-green/theme.css'
// import 'primereact/resources/themes/arya-orange/theme.css'
// import 'primereact/resources/themes/arya-purple/theme.css'
import DBContext, { DBContextStatus } from 'util/db/DBContext'
import useDBConnection from './util/db/useDBConnection'
import { PrimeReactProvider } from 'primereact/api'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import Login from 'components/login/Login'
import { useLocalStorage } from 'primereact/hooks'
import { Creds } from 'components/login/types'
import EntityChangeManager from './util/db/EntityChangeManager'
import PouchDB from 'pouchdb'
import LocalDevModal from './components/dev/LocalDevModal'
import LivestockPage from './components/livestock/LivestockPage'

const parseCreds = (serializedCreds: string): Creds => {
  try {
    return JSON.parse(serializedCreds) as Creds
  } catch (err) {
    return {
      username: 'invalid',
      password: 'invalid',
      org: 'invalid',
    }
  }
}

function App() {
  const [creds, setCreds] = useLocalStorage('', 'credentials')
  const [decodedCreds, setDecodedCreds] = useState<Creds>(parseCreds(creds))
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const { db, dbStatus } = useDBConnection(decodedCreds)

  // TODO: redact passwords / use bearer tokens (JWT)

  useEffect(() => {
    const newDecodedCreds = parseCreds(creds)
    if (newDecodedCreds.username !== 'invalid') {
      setDecodedCreds(newDecodedCreds)
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [creds])

  useEffect(() => {
    // Notify about connection change, take necessary
    // actions on connection re-establishment
  }, [dbStatus])

  return (
    <DBContext.Provider
      value={{
        db: loggedIn ? db : new PouchDB('offline'),
        dbStatus: dbStatus ?? DBContextStatus.UNINITIALIZED,
        entityChangeManager: loggedIn
          ? EntityChangeManager.getInstance(db)
          : undefined,
      }}
    >
      <PrimeReactProvider>
        <div className="App">
          {process.env.NODE_ENV === 'development' ? <LocalDevModal /> : null}
          {loggedIn ? (
            <LivestockPage />
          ) : (
            <Login setCreds={setCreds} setLoggedIn={setLoggedIn} />
          )}
        </div>
      </PrimeReactProvider>
    </DBContext.Provider>
  )
}

export default App
