import React, { useRef } from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Messages } from 'primereact/messages'
import LivestockTreeView from './LivestockTreeView'
import LivestockTable from './LivestockTable'

const LivestockPage = () => {
  const messages = useRef<Messages>(null)

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full">
        <TabView activeIndex={1}>
          <Messages ref={messages} />
          <TabPanel header="Table">
            <div className="block">
              <Card>
                <div className="flex justify-content-end pb-3">
                  <Button
                    onClick={() => {
                      localStorage.clear()
                      window.location.reload()
                    }}
                  >
                    Logout
                  </Button>
                </div>
                <LivestockTable messages={messages} />
              </Card>
            </div>
          </TabPanel>
          <TabPanel header="Tree">
            <LivestockTreeView />
          </TabPanel>
        </TabView>
      </div>
    </div>
  )
}

export default LivestockPage
