import { useContext } from 'react'
import DBContext from './DBContext'

const useWatchEntity = (
  entity: string,
  callback: (change: PouchDB.Core.ChangesResponseChange<any>) => void
) => {
  const { entityChangeManager } = useContext(DBContext)
  entityChangeManager?.addListener(entity, callback)

  return {
    cancel: () => entityChangeManager?.removeListener(entity),
  }
}

export default useWatchEntity
