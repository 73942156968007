import { InputText } from 'primereact/inputtext'
import React, {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { LoginError } from './types'
import { Buffer } from 'buffer'
import { Messages } from 'primereact/messages'
import { useUnmountEffect } from 'primereact/hooks'

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://db.steadlite.com'
    : 'http://127.0.0.1:5984'

const attemptLogin = async (
  username: string,
  password: string,
  org: string
): Promise<{
  success: boolean
  error?: LoginError
}> => {
  const res = await fetch(`${baseUrl}/_session`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      name: username,
      password,
    }),
  })
  if (res.status === 200) {
    // Check Org access
    const hasAccess = await fetch(`${baseUrl}/steadlite-${org}`, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${Buffer.from(`${username}:${password}`, 'utf-8').toString('base64')}`,
      },
    })
    if (hasAccess.status === 200) {
      return {
        success: true,
      }
    } else {
      return {
        success: false,
        error: LoginError.E_NO_ACCESS_TO_ORG,
      }
    }
  }
  return {
    success: false,
    error: LoginError.E_UNAUTHORIZED,
  }
}

const onSubmit = async ({
  username,
  password,
  org,
  setCreds,
  setError,
  messages,
}: {
  username: string
  password: string
  org: string
  setCreds: Dispatch<SetStateAction<string>>
  setError: Dispatch<SetStateAction<LoginError | null>>
  messages: RefObject<Messages>
}): Promise<boolean> => {
  const login = await attemptLogin(username, password, org)
  if (login.success) {
    setCreds(JSON.stringify({ username, password, org }))
    return true
  } else {
    setCreds('')
    setError(login.error ?? LoginError.E_UNKNOWN_ERROR)
    messages.current?.show({
      severity: 'error',
      summary: 'Failed to login',
      sticky: true,
      detail:
        login.error === LoginError.E_NO_ACCESS_TO_ORG
          ? `You do not have access to this Stead (if it exists)`
          : login.error === LoginError.E_UNAUTHORIZED
            ? 'Incorrect email / password'
            : 'Unknown error',
    })
    return false
  }
}

const Login: FC<{
  setCreds: Dispatch<SetStateAction<string>>
  setLoggedIn: Dispatch<SetStateAction<boolean>>
}> = ({ setCreds, setLoggedIn }) => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [org, setOrg] = useState<string>('')
  const [error, setError] = useState<LoginError | null>(null)
  const messages = useRef<Messages>(null)

  useUnmountEffect(() => {
    if (messages.current) {
      messages.current.clear()
    }
  })

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          {/*<img*/}
          {/*  src="/demo/images/blocks/logos/hyper.svg"*/}
          {/*  alt="hyper"*/}
          {/*  height={50}*/}
          {/*  className="mb-3"*/}
          {/*/>*/}
          <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
          <span className="text-600 font-medium line-height-3">
            Don't have an account?
          </span>
          <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">
            Create today!
          </a>
        </div>
        <div>
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              setLoggedIn(
                await onSubmit({
                  username,
                  password,
                  org,
                  setCreds,
                  setError,
                  messages,
                })
              )
            }}
          >
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              autoFocus
              required
              invalid={!!error}
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              onChange={(e) => setUsername(e.target.value)}
            />
            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              required
              invalid={!!error}
              id="password"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div>
              <label htmlFor="org" className="bock text-900 font-medium mb-2">
                Stead
              </label>
            </div>
            <InputText
              required
              invalid={!!error}
              id="org"
              type="text"
              className="w-full mb-3"
              onChange={(e) => setOrg(e.target.value)}
            />
            <div className="flex align-items-center justify-content-between mb-6">
              <div className="flex align-items-center">
                <Checkbox
                  id="rememberme"
                  onChange={(e) => {}}
                  checked
                  className="mr-2"
                />
                <label htmlFor="rememberme">Remember me</label>
              </div>
              <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                Forgot your password?
              </a>
            </div>
            <Messages ref={messages} />
            <Button type="submit" label="Sign In" icon="pi pi-user" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
