import { Livestock } from 'components/livestock/types'
import { TreeSeriesNodeItemOption } from 'echarts/types/src/chart/tree/TreeSeries'

const findAncestors = async (
  db: PouchDB.Database,
  curr: Livestock | null,
  maxDepth: number = 4,
  currentDepth: number = 0
): Promise<TreeSeriesNodeItemOption | null> => {
  if (!curr || currentDepth == maxDepth) {
    return {
      name: 'null',
    }
  }
  const currMother = curr?.mother?._id
    ? await findAncestors(
        db,
        (await db.get(curr?.mother?._id)) as Livestock,
        maxDepth,
        ++currentDepth
      )
    : null
  const currFather = curr?.father?._id
    ? await findAncestors(
        db,
        (await db.get(curr?.father?._id)) as Livestock,
        maxDepth,
        ++currentDepth
      )
    : null
  // @ts-ignore
  const children: TreeSeriesNodeItemOption[] = [currMother, currFather].filter(
    (node) => node
  )
  return {
    name: curr.name ?? 'N/A',
    children: children.length ? children : undefined,
    label: {
      show: true,
      position: 'top',
      backgroundColor: '#eee',
      borderColor: '#333',
      borderWidth: 2,
      borderRadius: 5,
      padding: 5,
      color: '#000',
      fontSize: 14,
      shadowBlur: 3,
      shadowColor: '#888',
      shadowOffsetX: 0,
      shadowOffsetY: 3,
      lineHeight: 14,
      align: 'left',
      formatter: [
        curr.name,
        `Tag: ${curr.tag_id ?? 'N/A'}`,
        `Breed: ${curr.breed?.name ?? 'N/A'}`,
        `Gender: ${curr.gender ?? 'N/A'}`,
      ].join('\n'),
    },
  }
}

export const buildTreeFromLivestock = async (
  db: PouchDB.Database,
  start?: Livestock,
  maxDepth: number = 4
): Promise<TreeSeriesNodeItemOption | null> => {
  if (start) {
    const tree = (await findAncestors(db, start, maxDepth)) ?? null
    return tree ? tree : null
  }
  return null
}
