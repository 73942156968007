import React, { useContext, useEffect, useState } from 'react'
import DBContext from '../../util/db/DBContext'
import { Livestock } from './types'
import { buildTreeFromLivestock } from './util'
import { ProgressBar } from 'primereact/progressbar'
import { Dropdown } from 'primereact/dropdown'
import { ReactEChart, ReactEChartsProps } from '../../shared/data/ReactEChart'
import { TreeSeriesNodeItemOption } from 'echarts/types/src/chart/tree/TreeSeries'

const fetchData = async (db: PouchDB.Database): Promise<Livestock[]> => {
  const res = await db.find({
    selector: {
      type: 'livestock',
    },
  })
  return res.docs as Livestock[]
}

const LivestockTreeView = () => {
  const { db } = useContext(DBContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [livestockDropdownOptions, setLivestockDropdownOptions] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [selectedLivestockId, setSelectedLivestockId] = useState<string>()
  const [selectedLivestock, setSelectedLivestock] = useState<Livestock>()
  const [livestockTree, setLivestockTree] = useState<TreeSeriesNodeItemOption>(
    {}
  )

  useEffect(() => {
    fetchData(db).then((data) => {
      setLivestockDropdownOptions(
        data.map((l) => ({
          label: l.name ?? 'N/A',
          value: l._id,
        }))
      )
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const getCurrentLivestock = async () => {
      if (selectedLivestockId) {
        setLoading(true)
        const res = await db.get(selectedLivestockId)
        setSelectedLivestock(res as Livestock)
        const treeBuilt = await buildTreeFromLivestock(db, res as Livestock)
        if (treeBuilt) {
          setLivestockTree(treeBuilt)
        }
        setLoading(false)
      }
    }
    getCurrentLivestock().then()
  }, [selectedLivestockId, db])

  //////////////////////////////
  const option: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
    },

    title: {
      text: 'Ancestry',
      show: true,
      mainType: 'title',
      right: 'center',
    },
    series: [
      {
        type: 'tree',
        data: [livestockTree],
        top: '1%',
        left: '7%',
        bottom: '1%',
        right: '20%',
        symbolSize: 7,
        label: {
          position: 'left',
          verticalAlign: 'middle',
          align: 'right',
          fontSize: 14,
        },
        leaves: {
          label: {
            position: 'right',
            verticalAlign: 'middle',
            align: 'left',
          },
        },
        emphasis: {
          focus: 'descendant',
        },
        expandAndCollapse: true,
        animationDuration: 550,
        animationDurationUpdate: 750,
      },
    ],
  }
  //////////////////////////////

  return loading ? (
    <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  ) : (
    <>
      <Dropdown
        filter
        placeholder="Select a livestock"
        options={livestockDropdownOptions}
        onChange={(e) => setSelectedLivestockId(e.value)}
        value={selectedLivestockId}
      />
      <ReactEChart
        option={option}
        style={{
          height: '50vh',
        }}
      />
    </>
  )
}

export default LivestockTreeView
