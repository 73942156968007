import { ColumnFilterElementTemplateOptions } from 'primereact/column'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'
import {
  Breed,
  Livestock,
  Species,
  TableLivestock,
} from 'components/livestock/types'
import React, {
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useState,
} from 'react'
import SheepIcon from 'assets/icons/sheep.png'
import DBContext from '../../../util/db/DBContext'
import { ProgressBar } from 'primereact/progressbar'
import AddOrEditLivestock from '../AddOrEditLivestock'
import { Messages } from 'primereact/messages'

export const speciesFilterTemplate =
  (species: Species[]) =>
  (options: ColumnFilterElementTemplateOptions): ReactNode => (
    <Dropdown
      value={options.value}
      options={species.map((s) => ({
        label: s._id,
        value: s._id,
      }))}
      onChange={(e: DropdownChangeEvent) =>
        options.filterApplyCallback(e.target.value, options.index)
      }
      itemTemplate={(option: { label: string; value: string }) => (
        <>
          <Tag value={option.label} />
        </>
      )}
    />
  )

export const speciesBodyTemplate = (rowData: TableLivestock) => (
  <>
    <Tag rounded severity="info">
      <div className="flex align-items-center justify-content-between">
        <img
          alt="sheep icon"
          className="pr-1"
          src={SheepIcon}
          style={{ height: '1.5rem', width: '1.5rem' }}
        />
        <span className="text-base">{rowData.species}</span>
      </div>
    </Tag>
  </>
)

export const genderFilterTemplate = (
  options: ColumnFilterElementTemplateOptions
) => (
  <>
    <Dropdown
      value={options.value}
      options={[
        {
          label: 'Male',
          value: 'Male',
        },
        {
          label: 'Female',
          value: 'Female',
        },
      ]}
      onChange={(e: DropdownChangeEvent) =>
        options.filterApplyCallback(e.target.value, options.index)
      }
      itemTemplate={(option: { label: string; value: string }) => (
        <>
          <Tag value={option.label} />
        </>
      )}
    />
  </>
)

interface LivestockTableRowExpansionProps {
  data: TableLivestock
  messages: RefObject<Messages>
  species: Species[]
  breeds: Breed[]
}

export const RowExpansionTemplate = ({
  data,
  messages,
  species,
  breeds,
}: LivestockTableRowExpansionProps) => {
  const [loading, setLoading] = useState(true)
  const [livestockBeingEdited, setLivestockBeingEdited] = useState<
    Livestock | undefined
  >(undefined)
  const { db } = useContext(DBContext)
  useEffect(() => {
    data?._id &&
      db.get(data._id).then((res) => setLivestockBeingEdited(res as Livestock))
    setLoading(false)
  }, [data])
  return (
    <>
      {loading || !livestockBeingEdited ? (
        <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
      ) : (
        <AddOrEditLivestock
          variant="edit"
          messages={messages}
          species={species}
          breeds={breeds}
          livestockBeingEdited={livestockBeingEdited}
        />
      )}
    </>
  )
}
