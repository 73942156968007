import React, {
  FC,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useState,
} from 'react'
import DBContext from 'util/db/DBContext'
import {
  DataTable,
  DataTableExpandedRows,
  DataTableFilterMeta,
  DataTableValueArray,
} from 'primereact/datatable'
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import {
  useMountEffect,
  useResizeListener,
  useUnmountEffect,
} from 'primereact/hooks'
import AddOrEditLivestock from './AddOrEditLivestock'
import { Breed, Livestock, Species, TableLivestock } from './types'
import RemoveLivestockDialog from 'components/livestock/RemoveLivestockDialog'
import useWatchEntity from 'util/db/useWatchEntity'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { Button } from 'primereact/button'
import { FilterMatchMode } from 'primereact/api'
import { IconField } from 'primereact/iconfield'
import {
  genderFilterTemplate,
  RowExpansionTemplate,
  speciesBodyTemplate,
  speciesFilterTemplate,
} from './data_templates'
import { Messages } from 'primereact/messages'
import { InputText } from 'primereact/inputtext'
import { InputIcon } from 'primereact/inputicon'

type LivestockTableColumn = {
  field: string
  header: string
  hidden?: boolean
  sortable?: boolean
  filter?: boolean
  body?: (rowData: TableLivestock) => ReactNode
  filterElement?:
    | ReactNode
    | ((options: ColumnFilterElementTemplateOptions) => ReactNode)
  hideApplyButton?: boolean
  hideFilterMatchModes?: boolean
  style?: React.CSSProperties
}

const LivestockTable: FC<{
  messages: RefObject<Messages>
}> = ({ messages }) => {
  const { db } = useContext(DBContext)
  const [tableData, setTableData] = useState<Livestock[]>([])
  const [species, setSpecies] = useState<Species[]>([])
  const [breeds, setBreeds] = useState<Breed[]>([])
  const [selectedItems, setSelectedItems] = useState<TableLivestock[]>([])
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [filters, setFilters] = useState<DataTableFilterMeta>({})
  const changeWatcher = useWatchEntity('livestock', () => fetchTableData())
  // TODO: Make this (mobile checks) reusable
  const mobileBreakpoint = 480
  const [mobile, setMobile] = useState(window.innerWidth <= mobileBreakpoint)
  const [columns, setColumns] = useState<LivestockTableColumn[]>([])
  const [visibleColumns, setVisibleColumns] = useState<LivestockTableColumn[]>(
    []
  )
  const [expandedRows, setExpandedRows] = useState<
    DataTableValueArray | DataTableExpandedRows
  >()

  useEffect(() => {
    const allColumns = [
      {
        field: '_id',
        header: '_id',
        hidden: true,
      },
      {
        field: 'tag_id',
        header: 'Tag',
        sortable: true,
        filter: true,
        style: {
          width: '12rem',
          minWidth: '10rem',
        },
      },
      {
        field: 'species',
        header: 'Species',
        sortable: true,
        filter: true,
        body: speciesBodyTemplate,
        filterElement: speciesFilterTemplate(species),
        hideApplyButton: true,
        style: {
          maxWidth: '10rem',
          minWidth: '10rem',
        },
      },
      {
        field: 'name',
        header: 'Name',
        sortable: true,
        filter: true,
        style: {
          minWidth: '10rem',
        },
      },
      {
        field: 'gender',
        header: 'Gender',
        sortable: true,
        filter: true,
        filterElement: genderFilterTemplate,
        hideApplyButton: true,
        hideFilterMatchModes: true,
        style: {
          minWidth: '6rem',
        },
      },
      {
        field: 'breed',
        header: 'Breed',
        sortable: true,
        //filter: true,
        style: {
          maxWidth: '10rem',
        },
      },
      {
        field: 'mother',
        header: 'Mother',
        sortable: true,
        filter: true,
      },
      {
        field: 'father',
        header: 'Father',
        sortable: true,
        filter: true,
      },
      {
        field: 'status',
        header: 'Status',
        sortable: true,
        filter: true,
      },
    ]
    setColumns(allColumns)
    setVisibleColumns(mobile ? allColumns.slice(0, 3) : allColumns)
  }, [species, mobile])

  const [bindResizeListener, unbindResizeListener] = useResizeListener({
    listener: (event) => {
      if (
        (event.currentTarget as unknown as { innerWidth: number })
          ?.innerWidth <= 480
      ) {
        setMobile(true)
        setVisibleColumns(columns.slice(0, 3))
      } else {
        setMobile(false)
        setVisibleColumns(columns)
      }
    },
  })

  const fetchTableData = async () => {
    const res: PouchDB.Find.FindResponse<{ type?: string }> = await db.find({
      selector: {
        type: {
          $in: ['livestock', 'species', 'breed'],
        },
      },
    })
    setTableData(
      res.docs.filter((doc) => doc.type === 'livestock') as Livestock[]
    )
    setSpecies(res.docs.filter((doc) => doc.type === 'species') as Species[])
    setBreeds(res.docs.filter((doc) => doc.type === 'breed') as Breed[])
  }

  useEffect(() => {
    bindResizeListener()
    return () => unbindResizeListener()
  }, [bindResizeListener, unbindResizeListener])

  useMountEffect(() => {
    initFilters()
    fetchTableData().then(() => {})
  })

  useUnmountEffect(() => {
    if (messages.current) {
      messages.current.clear()
    }
    changeWatcher.cancel()
  })

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter(
      (col) =>
        !col.hidden &&
        selectedColumns.some(
          (sCol: LivestockTableColumn) => sCol.field === col.field
        )
    )
    setVisibleColumns(orderedSelectedColumns)
  }

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = { ...filters }
    // @ts-ignore
    if (_filters['global']?.value) {
      // @ts-ignore
      _filters['global'].value = value
    }
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilters = () => {
    setGlobalFilterValue('')
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      _id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      tag_id: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      species: { value: null, matchMode: FilterMatchMode.EQUALS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      gender: { value: null, matchMode: FilterMatchMode.EQUALS },
      breed: { value: null, matchMode: FilterMatchMode.CONTAINS },
      mother: { value: null, matchMode: FilterMatchMode.CONTAINS },
      father: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    })
  }

  const clearFilters = () => {
    initFilters()
  }

  const header = () => (
    <div className="flex flex-row justify-content-between align-items-end">
      <div className="flex flex-column gap-3">
        <div>
          <label htmlFor="visible-column-select">Show Columns:</label>
        </div>
        <div className="flex flex-row justify-content-between">
          <div>
            <MultiSelect
              id="visible-column-select"
              value={visibleColumns.filter((col) => !col.hidden)}
              options={columns.filter((col) => !col.hidden)}
              optionLabel="header"
              onChange={onColumnToggle}
              className="w-4rem md:w-10rem lg:w-20rem"
              display="chip"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-column gap-3 align-items-end">
          <div className="flex flex-row gap-3 align-items-center">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear Filters"
              outlined
              onClick={clearFilters}
            />
          </div>
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              className="w-4rem md:w-10rem lg:w-20rem pi pi-search-input"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search..."
            />
          </IconField>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Toolbar
        className="mb-4"
        start={
          <div className="flex flex-wrap gap-2">
            {selectedItems.length === 0 && (
              <AddOrEditLivestock
                variant="add"
                modal
                messages={messages}
                species={species}
                breeds={breeds}
              />
            )}
            {selectedItems.length > 0 && (
              <RemoveLivestockDialog
                messages={messages}
                selectedItems={selectedItems}
                onConfirm={() => {
                  fetchTableData().then(() => setSelectedItems([]))
                }}
              />
            )}
          </div>
        }
      />
      <DataTable
        value={
          tableData.map((d) => ({
            _id: d._id,
            _rev: d._rev,
            tag_id: d.tag_id,
            name: d.name,
            gender: d.gender,
            species: d.species,
            breed: d.breed?.name,
            mother: d.mother?.name,
            father: d.father?.name,
            status: d.status,
          })) as TableLivestock[]
        }
        globalFilterFields={[
          'tag_id',
          'species',
          'name',
          'gender',
          'breed',
          'mother',
          'father',
          'status',
        ]}
        resizableColumns
        columnResizeMode="fit"
        reorderableColumns
        sortIcon={(props) => <></>}
        //stateStorage="local"
        //stateKey="default-saved-filters"
        emptyMessage="No livestock found."
        filters={filters}
        globalFilter={globalFilterValue}
        selectionMode="multiple"
        selection={selectedItems}
        dataKey="_id"
        onSelectionChange={(e) => setSelectedItems(e.value)}
        metaKeySelection={false}
        size="small"
        header={header}
        removableSort
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={(data: TableLivestock) => (
          <RowExpansionTemplate
            data={data}
            messages={messages}
            species={species}
            breeds={breeds}
          />
        )}
      >
        <Column selectionMode="multiple" />
        <Column expander />
        <Column rowEditor />
        {visibleColumns.map((col) => (
          <Column
            key={col.field}
            hidden={col.hidden}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            filter={col.filter}
            body={col.body}
            filterElement={col.filterElement}
            showApplyButton={!col.hideApplyButton}
            showFilterMatchModes={!col.hideFilterMatchModes}
            style={col.style}
          />
        ))}
      </DataTable>
    </>
  )
}

export default LivestockTable
