import { DBContextStatus } from './DBContext'
import React from 'react'
import PouchDB from 'pouchdb'
import { Creds } from 'components/login/types'

PouchDB.plugin(require('pouchdb-find').default)

const useDBConnection = (creds: Creds) => {
  const { org } = creds
  const prod = process.env.NODE_ENV === 'production'
  const proto = prod ? 'https://' : 'http://'
  const host = prod ? 'db.steadlite.com' : '127.0.0.1:5984'
  const remoteDB =
    creds.username !== 'invalid'
      ? new PouchDB(`${proto}${host}/steadlite-${org}`, {
          auth: {
            username: creds.username,
            password: creds.password,
          },
        })
      : new PouchDB('offline')
  const localDB = new PouchDB(`steadlite-${org}`)
  const [dbStatus, setDBStatus] = React.useState<DBContextStatus>(
    DBContextStatus.UNINITIALIZED
  )

  if (remoteDB.name !== 'offline') {
    localDB.replicate
      .from(remoteDB, {})
      .on('complete', () => {
        setDBStatus(DBContextStatus.INITIALIZED)
        try {
          localDB.sync(remoteDB, {
            live: true,
            retry: true,
            timeout: 2000,
          })
        } catch (err) {
          console.log('Error:', err)
        }
      })
      .on('error', () => {
        setDBStatus(DBContextStatus.OFFLINE)
      })
  }
  if (dbStatus === DBContextStatus.INITIALIZED) {
    //
  } else if (dbStatus === DBContextStatus.OFFLINE) {
    //
  }

  return {
    db: localDB,
    dbStatus,
  }
}

export default useDBConnection
