import React, { FC, RefObject, useContext, useEffect, useState } from 'react'
import { Messages } from 'primereact/messages'
import { Breed, Livestock, Species } from './types'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { v4 as uuid } from 'uuid'
import { InputText } from 'primereact/inputtext'
import DBContext from 'util/db/DBContext'

export interface AddOrEditLivestockProps {
  messages: RefObject<Messages>
  species: Species[]
  breeds: Breed[]
  variant: 'add' | 'edit'
  modal?: boolean
  livestockBeingEdited?: Livestock
}

const fetchParentalOptions = async (db: PouchDB.Database, species: string) => {
  const options = await db.find({
    selector: {
      type: 'livestock',
      species,
    },
  })
  return options.docs as Livestock[]
}

const AddOrEditLivestock: FC<AddOrEditLivestockProps> = ({
  messages,
  species,
  breeds,
  variant,
  modal = false,
  livestockBeingEdited,
}) => {
  const { db } = useContext(DBContext)
  const [visible, setVisible] = React.useState(false)
  const [selectedTagId, setSelectedTagId] = useState<string | null>(null)
  const [selectedSpecies, setSelectedSpecies] = useState<string>('N/A')
  const [selectedBreed, setSelectedBreed] = useState<Breed | null>(null)
  const [selectedName, setSelectedName] = useState<string | null>(null)
  const [selectedGender, setSelectedGender] = useState<string | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<string>('alive')
  const [selectedMother, setSelectedMother] = useState<string | null>(null)
  const [selectedFather, setSelectedFather] = useState<string | null>(null)
  const [availableParents, setAvailableParents] = useState<Livestock[]>([])
  const speciesMap: { [_id: string]: Species } = {}
  const breedMap: { [_id: string]: Breed } = {}

  console.log('messages:', messages)

  species.forEach((s) => (speciesMap[s._id] = s))
  breeds.forEach((b) => (breedMap[b._id] = b))

  const handleSelectedTagChange = (value: string) => {
    if (variant === 'edit' && livestockBeingEdited) {
      livestockBeingEdited.tag_id = value
    }
    setSelectedTagId(value)
  }

  const handleSelectedNameChange = (value: string) => {
    if (variant === 'edit' && livestockBeingEdited) {
      livestockBeingEdited.name = value
    }
    setSelectedName(value)
  }

  const handleSelectedBreedChange = (value: string) => {
    if (variant === 'edit' && livestockBeingEdited) {
      livestockBeingEdited.breed = {
        _id: value,
        name: breedMap[value]?.name ?? 'N/A',
      }
    }
    setSelectedBreed(breedMap[value])
  }

  const handleSelectedGenderChange = (value: string) => {
    if (variant === 'edit' && livestockBeingEdited) {
      livestockBeingEdited.gender = value
    }
    setSelectedGender(value)
  }

  const handleSelectedMotherChange = (value: string) => {
    if (variant === 'edit' && livestockBeingEdited) {
      livestockBeingEdited.mother = {
        _id: value,
        name: availableParents.find((p) => p._id === value)?.name ?? 'N/A',
        //name: '',
      }
    }
    setSelectedMother(value)
  }

  const handleSelectedFatherChange = (value: string) => {
    if (variant === 'edit' && livestockBeingEdited) {
      livestockBeingEdited.father = {
        _id: value,
        name: availableParents.find((p) => p._id === value)?.name ?? 'N/A',
        //name: '',
      }
    }
    setSelectedFather(value)
  }

  const handleSelectedStatusChange = (value: string) => {
    if (variant === 'edit' && livestockBeingEdited) {
      livestockBeingEdited.status = value
    }
    console.log('livestockBeingEdited', livestockBeingEdited)
    setSelectedStatus(value)
  }

  const handleAddLivestockSubmit = async () => {
    setVisible(false)
    db.put({
      _id: uuid(),
      tag_id: selectedTagId,
      type: 'livestock',
      species: selectedSpecies,
      breed: {
        _id: selectedBreed?._id,
        name: selectedBreed?.name,
      },
      name: selectedName,
      gender: selectedGender,
      attributes: {},
      status: selectedStatus,
    })
      .then(() => {
        messages.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Livestock Added',
        })
        setSelectedTagId(null)
        setSelectedName(null)
      })
      .catch(() => {
        // error
        messages.current?.show({
          sticky: true,
          severity: 'error',
          summary: 'Failed',
          detail: 'Unable to add livestock',
        })
      })
  }

  const handleUpdateLivestockSubmit = async () => {
    if (livestockBeingEdited) {
      db.put({
        _id: livestockBeingEdited?._id,
        _rev: livestockBeingEdited?._rev,
        tag_id: livestockBeingEdited?.tag_id,
        type: 'livestock',
        species: livestockBeingEdited?.species,
        breed: {
          _id: livestockBeingEdited?.breed?._id,
          name: livestockBeingEdited?.breed?.name,
        },
        name: livestockBeingEdited?.name,
        gender: livestockBeingEdited?.gender,
        attributes: {},
        mother: {
          _id: livestockBeingEdited?.mother?._id,
          name: livestockBeingEdited?.mother?.name,
        },
        father: {
          _id: livestockBeingEdited?.father?._id,
          name: livestockBeingEdited?.father?.name,
        },
        status: livestockBeingEdited?.status,
      })
        .then(() => {
          messages.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Livestock updated',
          })
        })
        .catch(() => {
          // error
          messages.current?.show({
            sticky: true,
            severity: 'error',
            summary: 'Failed',
            detail: 'Unable to update livestock',
          })
        })
    }
  }

  useEffect(() => {
    const species =
      variant === 'edit'
        ? livestockBeingEdited?.species ?? 'N/A'
        : selectedSpecies
    selectedSpecies &&
      fetchParentalOptions(db, species).then((data) =>
        setAvailableParents(data)
      )
  }, [selectedSpecies])

  const content = (
    <div
      className={
        variant === 'add' ? 'card flex flex-column gap-3' : 'formgrid grid'
      }
    >
      <div className="p-inputgroup field col-12 md:col-6">
        <span className="p-inputgroup-addon">Tag</span>
        <InputText
          autoFocus
          placeholder="#1"
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited.tag_id
              : selectedTagId ?? ''
          }
          onChange={(e) => handleSelectedTagChange(e.target.value)}
        />
      </div>
      <div className="p-inputgroup field col-12 md:col-6">
        <span className="p-inputgroup-addon">Name</span>
        <InputText
          placeholder="Betsy"
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited.name
              : selectedName ?? ''
          }
          onChange={(e) => handleSelectedNameChange(e.target.value)}
        />
      </div>
      <div className="p-inputgroup field col-12 md:col-4">
        <span className="p-inputgroup-addon">Species</span>
        <Dropdown
          disabled={variant === 'edit'}
          required
          options={Object.values(speciesMap).map((s) => ({
            label: s._id,
            value: s._id,
          }))}
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited.species
              : selectedSpecies
          }
          onChange={(e) => setSelectedSpecies(e.value)}
        />
      </div>
      <div className="p-inputgroup field col-12 md:col-4">
        <span className="p-inputgroup-addon">Breed</span>
        <Dropdown
          options={Object.values(breedMap)
            .filter((b) =>
              variant === 'edit'
                ? b.species === livestockBeingEdited?.species
                : b.species === selectedSpecies
            )
            .map((b) => ({
              label: b.name,
              value: b._id,
            }))}
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited.breed?._id
              : selectedBreed?._id
          }
          onChange={(e) => handleSelectedBreedChange(e.value ?? null)}
        />
      </div>
      <div className="p-inputgroup field col-12 md:col-4">
        <span className="p-inputgroup-addon">Gender</span>
        <Dropdown
          options={[
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' },
          ]}
          onChange={(e) => handleSelectedGenderChange(e.value)}
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited?.gender
              : selectedGender
          }
        />
      </div>
      <div className="p-inputgroup field col-12 md:col-6">
        <span className="p-inputgroup-addon">Mother</span>
        <Dropdown
          filter
          options={availableParents
            .filter((p) => p.gender === 'Female')
            .map((p) => ({
              label: p.name,
              value: p._id,
            }))}
          onChange={(e) => handleSelectedMotherChange(e.value)}
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited.mother?._id
              : selectedFather
          }
        />
      </div>
      <div className="p-inputgroup field col-12 md:col-6">
        <span className="p-inputgroup-addon">Father</span>
        <Dropdown
          filter
          options={availableParents
            .filter((p) => p.gender === 'Male')
            .map((p) => ({
              label: p.name,
              value: p._id,
            }))}
          onChange={(e) => handleSelectedFatherChange(e.value)}
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited.father?._id
              : selectedFather
          }
        />
      </div>

      <div className="p-inputgroup field col-12 md:col-6">
        <span className="p-inputgroup-addon">Status</span>
        <Dropdown
          options={[
            {
              label: 'Alive',
              value: 'alive',
            },
            {
              label: 'Deceased',
              value: 'deceased',
            },
          ]}
          onChange={(e) => handleSelectedStatusChange(e.value)}
          value={
            variant === 'edit' && livestockBeingEdited
              ? livestockBeingEdited?.status ?? 'alive'
              : selectedStatus ?? 'alive'
          }
        />
      </div>
    </div>
  )

  if (variant === 'add') {
    if (modal) {
      return (
        <>
          <Button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={() => setVisible(true)}
          />
          <Dialog
            header="Add Livestock"
            visible={visible}
            style={{ width: '75vw' }}
            footer={
              <div>
                <Button
                  label="Ok"
                  icon="pi pi-check"
                  onClick={handleAddLivestockSubmit}
                />
              </div>
            }
            onHide={() => setVisible(false)}
          >
            {content}
          </Dialog>
        </>
      )
    }
    return content
  } else if (variant === 'edit') {
    return (
      <>
        {content}
        <Button
          label="Save"
          icon="pi pi-save"
          severity="info"
          onClick={handleUpdateLivestockSubmit}
        />
      </>
    )
  } else {
    return content
  }
}

export default AddOrEditLivestock
