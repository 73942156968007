import { FC, RefObject, useContext, useState } from 'react'
import DBContext from '../../util/db/DBContext'
import { Messages } from 'primereact/messages'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { TableLivestock } from './types'

const successMessage = (messages: RefObject<Messages>) => () =>
  messages.current?.show({
    severity: 'success',
    summary: 'Success',
    detail: 'Livestock Removed',
  })

const failedMessage = (messages: RefObject<Messages>, err: any) => () => {
  messages.current?.show({
    severity: 'error',
    summary: 'Failed',
    detail: 'Unable to remove livestock',
  })
}
const RemoveLivestockDialog: FC<{
  messages: RefObject<Messages>
  selectedItems: TableLivestock[]
  onConfirm: () => void
}> = ({ messages, selectedItems, onConfirm }) => {
  const { db, entityChangeManager } = useContext(DBContext)
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Button
        label="Remove"
        icon="pi pi-trash"
        severity="danger"
        onClick={() => {
          setVisible(true)
        }}
      />
      <Dialog
        header="Remove Livestock"
        visible={visible}
        style={{ width: '75vw' }}
        footer={
          <div>
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={async () => {
                setVisible(false)
                if (selectedItems.length > 0) {
                  db.bulkDocs(
                    selectedItems.map((l) => ({
                      ...l,
                      _deleted: true,
                    }))
                  )
                    .then(() => {
                      successMessage(messages)
                      entityChangeManager?.notify('livestock')
                    })
                    .catch((err) => failedMessage(messages, err))
                    .finally(onConfirm)
                }
              }}
            />
          </div>
        }
        onHide={() => setVisible(false)}
      >
        <div className="card flex flex-column gap-3">
          <div>
            Removing {selectedItems.map((l) => `'${l.name}'`).join(', ')}
          </div>
          <div>Are you sure?</div>
        </div>
      </Dialog>
    </>
  )
}

export default RemoveLivestockDialog
