export default class EntityChangeManager {
  private static _instance: EntityChangeManager
  private static _db: PouchDB.Database
  private static _registry = new Map<
    string,
    (change: PouchDB.Core.ChangesResponseChange<any>) => void
  >()

  private constructor() {
    EntityChangeManager._instance = this
  }

  static getInstance(db: PouchDB.Database) {
    if (EntityChangeManager._instance === undefined) {
      EntityChangeManager._instance = new EntityChangeManager()
      EntityChangeManager._db = db
      EntityChangeManager._db
        .changes({
          live: true,
          since: 'now',
          include_docs: true,
        })
        .on('change', (change: PouchDB.Core.ChangesResponseChange<any>) => {
          EntityChangeManager._registry.get(change.doc?.['type'])?.(change)
          // Check if this is a notification submission.
          const match = change?.doc?._id?.match(/(.*)_last_update$/)
          if (match && match.length > 1) {
            EntityChangeManager._registry.get(match[1])?.(change)
          }
        })
    }
    return EntityChangeManager._instance
  }

  addListener(
    entity: string,
    callback: (change: PouchDB.Core.ChangesResponseChange<any>) => void
  ) {
    EntityChangeManager._registry.set(entity, callback)
  }

  removeListener(entity: string) {
    EntityChangeManager._registry.delete(entity)
  }

  async notify(entity: string) {
    let currentRev = undefined
    try {
      const doc = await EntityChangeManager._db.get(`${entity}_last_update`)
      currentRev = doc._rev
    } catch {
      console.log('Unable to get latest rev')
    }
    EntityChangeManager._db
      .put(
        {
          _id: `${entity}_last_update`,
          _rev: currentRev,
          timestamp: new Date().getTime(),
        },
        {
          force: true,
        }
      )
      .catch((err) => {
        console.log('err:', err)
      })
  }
}
