export interface Creds {
  username: string
  password: string
  org: string
}

export enum LoginError {
  E_UNAUTHORIZED,
  E_NO_ACCESS_TO_ORG,
  E_UNKNOWN_ERROR,
}
