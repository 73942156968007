import PouchDB from 'pouchdb'
import { createContext } from 'react'
import EntityChangeManager from './EntityChangeManager'

export enum DBContextStatus {
  UNINITIALIZED,
  OFFLINE,
  INITIALIZED,
}

const DBContext = createContext<{
  db: PouchDB.Database<{}>
  dbStatus: DBContextStatus
  entityChangeManager?: EntityChangeManager
}>({
  db: new PouchDB('offline'),
  dbStatus: DBContextStatus.UNINITIALIZED,
})

DBContext.displayName = 'DBContext'

export default DBContext
